var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c(
        "el-row",
        [
          _c("span", { staticClass: "title" }, [_vm._v("课堂任务")]),
          _c(
            "el-row",
            {
              staticClass: "row-bg",
              staticStyle: {
                "margin-top": "15px",
                padding: "10px",
                display: "flex",
                "align-items": "center",
              },
            },
            [
              _c("el-col", { attrs: { span: 5 } }, [
                _c("span", [
                  _vm._v(
                    "上课时间：" +
                      _vm._s(
                        _vm._f("formatDateStart")(_vm.lessonObject.start_time)
                      )
                  ),
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm._f("formatDateEnd")(_vm.lessonObject.end_time))
                  ),
                ]),
              ]),
              _c("el-col", { attrs: { span: 4 } }, [
                _c("span", [_vm._v("讲师：" + _vm._s(_vm.lecturerName))]),
              ]),
              _c("el-col", { attrs: { span: 4 } }, [
                _c("span", [_vm._v("助教：" + _vm._s(_vm.assustantNames))]),
              ]),
              _vm.lessonObject.lessonStudentList
                ? _c("el-col", { attrs: { span: 4 } }, [
                    _c("span", [
                      _vm._v(
                        "排课学员：" +
                          _vm._s(_vm.lessonObject.lessonStudentList.length)
                      ),
                    ]),
                  ])
                : _vm.lessonObject.studentNum
                ? _c("el-col", { attrs: { span: 4 } }, [
                    _c("span", [
                      _vm._v(
                        "排课学员：" + _vm._s(_vm.lessonObject.studentNum)
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _vm._v(" 课堂状态："),
                  _c("el-button", { attrs: { type: "text" } }, [
                    _vm._v(_vm._s(_vm.enterCourse)),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "row-bg", staticStyle: { "margin-top": "15px" } },
            [
              _c("el-button", {
                staticClass: "tb-button",
                attrs: {
                  type: "info",
                  icon: "el-icon-arrow-left",
                  size: "mini",
                  disabled: _vm.currentLesson === 0,
                },
                on: { click: _vm.prevLesson },
              }),
              _c(
                "span",
                {
                  staticClass: "inline-block",
                  domProps: { textContent: _vm._s(_vm.courseTitle) },
                },
                [_vm._v("这里显示课节名称")]
              ),
              _c("el-button", {
                staticClass: "tb-button",
                staticStyle: { "margin-left": "15px !important" },
                attrs: {
                  type: "info",
                  icon: "el-icon-arrow-right",
                  size: "mini",
                  disabled: _vm.currentLesson === _vm.courseUnitList.length - 1,
                },
                on: { click: _vm.nextLesson },
              }),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 28 } },
            [
              _c(
                "el-col",
                { attrs: { span: 13 } },
                [
                  _c(
                    "el-table",
                    {
                      staticClass: "tb-list",
                      staticStyle: { width: "100%  !important" },
                      attrs: {
                        data: _vm.taskList,
                        border: "",
                        fit: "",
                        "highlight-current-row": "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { align: "center", label: "序号", width: "100" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [_vm._v(_vm._s(scope.$index + 1))]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "任务标题" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "title-link",
                                    on: {
                                      click: function ($event) {
                                        return _vm.previewTask(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(scope.row.title))]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "类型", prop: "type" },
                      }),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "任务操作" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "left",
                                      trigger: "click",
                                      width: "238",
                                    },
                                    on: { hide: _vm.hideMethod },
                                  },
                                  [
                                    _c(
                                      "ul",
                                      { staticClass: "stuList clearfix" },
                                      [
                                        _c(
                                          "li",
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "stu-tit" },
                                              [_vm._v("全体")]
                                            ),
                                            _c("el-switch", {
                                              staticClass: "stu-switch",
                                              on: {
                                                change: function ($event) {
                                                  return _vm.switchCheckAll(
                                                    scope.row,
                                                    _vm.switchAll,
                                                    scope.$index
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.switchAll,
                                                callback: function ($$v) {
                                                  _vm.switchAll = $$v
                                                },
                                                expression: "switchAll",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._l(
                                          scope.row.taskUserList,
                                          function (value, index) {
                                            return _c(
                                              "li",
                                              { key: index },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "stu-tit" },
                                                  [_vm._v(_vm._s(value.name))]
                                                ),
                                                _c("el-switch", {
                                                  staticClass: "stu-switch",
                                                  attrs: {
                                                    "active-value": 1,
                                                    "inactive-value": 2,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.switchCheckItem(
                                                        value,
                                                        scope.$index,
                                                        index
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      value.status === "已启用"
                                                        ? 1
                                                        : 2,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        value,
                                                        "status === '已启用' ? 1 : 2",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "value.status === '已启用' ? 1 : 2",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          slot: "reference",
                                          type: "text",
                                        },
                                        slot: "reference",
                                      },
                                      [_vm._v("开放学员")]
                                    ),
                                  ],
                                  1
                                ),
                                scope.row.type === "测试"
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          slot: "reference",
                                          type: "text",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.openmarkingDialog(
                                              scope.row.id
                                            )
                                          },
                                        },
                                        slot: "reference",
                                      },
                                      [_vm._v("审阅试卷")]
                                    )
                                  : _vm._e(),
                                scope.row.type === "python"
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          slot: "reference",
                                          type: "text",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.submitListManage(
                                              scope.row.id
                                            )
                                          },
                                        },
                                        slot: "reference",
                                      },
                                      [_vm._v("查看提交")]
                                    )
                                  : _vm._e(),
                                scope.row.type === "编程"
                                  ? _c(
                                      "el-dropdown",
                                      {
                                        staticStyle: { "margin-left": "15px" },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "el-dropdown-link",
                                            attrs: { type: "primary" },
                                          },
                                          [
                                            _vm._v(" 更多"),
                                            _c("i", {
                                              staticClass:
                                                "el-icon-arrow-down el-icon--right",
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "el-dropdown-menu",
                                          {
                                            attrs: { slot: "dropdown" },
                                            slot: "dropdown",
                                          },
                                          [
                                            _c(
                                              "el-dropdown-item",
                                              {
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.queryItem(
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 查看题库 ")]
                                            ),
                                            _c(
                                              "el-dropdown-item",
                                              {
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.setTime(
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 设置查看时间 ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "互动课堂操作" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "left",
                                      trigger: "click",
                                      width: "238",
                                    },
                                    on: { hide: _vm.hideMethod },
                                  },
                                  [
                                    _c(
                                      "ul",
                                      { staticClass: "stuList clearfix" },
                                      [
                                        _c(
                                          "li",
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "stu-tit" },
                                              [_vm._v("全部学员")]
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "stu-switch",
                                                attrs: {
                                                  slot: "reference",
                                                  type: "text",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.sendAllUser(
                                                      scope.row,
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                                slot: "reference",
                                              },
                                              [_vm._v("统一下发")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._l(
                                          scope.row.taskUserList,
                                          function (value, index) {
                                            return _c(
                                              "li",
                                              { key: index },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "stu-tit" },
                                                  [_vm._v(_vm._s(value.name))]
                                                ),
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticClass: "stu-switch",
                                                    attrs: {
                                                      slot: "reference",
                                                      type: "text",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.sendSingleUser(
                                                          scope.row,
                                                          value.loginName,
                                                          value.name,
                                                          value,
                                                          scope.$index,
                                                          index
                                                        )
                                                      },
                                                    },
                                                    slot: "reference",
                                                  },
                                                  [_vm._v("下发任务")]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          slot: "reference",
                                          type: "text",
                                        },
                                        slot: "reference",
                                      },
                                      [_vm._v("下发任务")]
                                    ),
                                  ],
                                  1
                                ),
                                scope.row.type === "编程" ||
                                scope.row.type === "python"
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          slot: "reference",
                                          type: "text",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.openCodePage(
                                              scope.row.type,
                                              scope.row.id
                                            )
                                          },
                                        },
                                        slot: "reference",
                                      },
                                      [_vm._v("查看代码")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-table",
                    {
                      ref: "userTable",
                      staticClass: "tb-list",
                      staticStyle: { width: "100%  !important" },
                      attrs: {
                        data: _vm.taskUserList,
                        border: "",
                        fit: "",
                        "highlight-current-row": "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { align: "center", label: "姓名" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [_vm._v(_vm._s(scope.row.name))]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "学号" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.loginName)),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "状态" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.state === 1
                                  ? _c("span", [_vm._v("在线")])
                                  : scope.row.state === 0
                                  ? _c("span", [_vm._v("离线")])
                                  : _c("span", [_vm._v("无")]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "正在做的任务",
                          width: "180",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.state === 1 &&
                                scope.row.pageState === 1
                                  ? _c("span", [
                                      _vm._v(_vm._s(scope.row.pageTitle)),
                                    ])
                                  : _vm._e(),
                                scope.row.pageState === -1 ||
                                scope.row.pageState !== 1
                                  ? _c("span", [_vm._v("无")])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("marking-exampaper", {
            attrs: {
              class_id: _vm.class_id,
              lessonId: _vm.lessonId,
              show: _vm.markingDialog,
              taskID: _vm.taskID,
            },
            on: {
              close: function ($event) {
                _vm.markingDialog = false
              },
            },
          }),
          _c("missed-lesson", {
            attrs: {
              applyCourseData: _vm.missLessonCourseData,
              show: _vm.missLessonCourseFlag,
            },
            on: {
              close: function ($event) {
                _vm.missLessonCourseFlag = false
              },
            },
          }),
          _c("set-history-time", {
            attrs: {
              taskId: _vm.taskID,
              lessonId: _vm.lesson_id,
              show: _vm.sethistoryDialog,
            },
            on: {
              close: function ($event) {
                _vm.sethistoryDialog = false
              },
            },
          }),
          _c(
            "el-drawer",
            {
              attrs: {
                visible: _vm.drawer,
                "with-header": false,
                direction: "rtl",
                size: "50%",
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.drawer = $event
                },
              },
            },
            [
              _c(
                "el-row",
                { staticStyle: { "overflow-y": "scroll" } },
                [
                  _c(
                    "el-tabs",
                    {
                      on: { "tab-click": _vm.handleClick },
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    _vm._l(_vm.dataOnlineList, function (item) {
                      return _c("el-tab-pane", {
                        attrs: { label: item.name, name: item.loginName },
                      })
                    }),
                    1
                  ),
                  _vm.codeTypeText === "编程"
                    ? _c("div", { ref: "ace", staticClass: "ace-editor" })
                    : _vm._e(),
                  _vm.codeTypeText === "python"
                    ? _c("el-row", { staticClass: "main" }, [
                        _c("iframe", {
                          ref: "leftFrame",
                          attrs: {
                            src: _vm.pathUrl,
                            frameborder: "1",
                            allowfullscreen: "true",
                            width: "100%",
                            height: "800",
                            seamless: "",
                            sandbox:
                              "allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups",
                          },
                        }),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }