<template>
  <el-row  class="table">
    <el-row>
      <span class="title">课堂任务</span>
      <el-row class="row-bg" style="margin-top: 15px;padding: 10px;display: flex;align-items: center">
        <el-col :span="5"> <span>上课时间：{{lessonObject.start_time| formatDateStart}}</span> <span>{{ lessonObject.end_time | formatDateEnd }}</span></el-col>
        <el-col :span="4"> <span>讲师：{{lecturerName}}</span></el-col>
        <el-col :span="4"> <span>助教：{{assustantNames}}</span></el-col>
        <el-col :span="4" v-if="lessonObject.lessonStudentList"> <span>排课学员：{{lessonObject.lessonStudentList.length}}</span></el-col>
        <el-col :span="4" v-else-if="lessonObject.studentNum"> <span>排课学员：{{lessonObject.studentNum}}</span></el-col>
        <el-col :span="3"> 课堂状态：<el-button type="text">{{enterCourse}}</el-button></el-col>
      </el-row>
      <el-row class="row-bg"  style="margin-top: 15px;">
        <el-button type="info" icon="el-icon-arrow-left" class="tb-button" size="mini" @click="prevLesson" :disabled="currentLesson === 0"></el-button>
        <span class="inline-block" v-text="courseTitle">这里显示课节名称</span>
        <el-button type="info" icon="el-icon-arrow-right" class="tb-button" size="mini" style="margin-left: 15px !important;" @click="nextLesson" :disabled="currentLesson === courseUnitList.length-1"></el-button>
      </el-row>
      <el-row :gutter = '28'>
        <el-col :span="13">
          <el-table
            style="width: 100%  !important;"
            :data="taskList"
            border
            fit
            highlight-current-row
            class="tb-list"
          >
            <el-table-column align="center" label="序号" width="100">
              <template slot-scope="scope">
                <span>{{scope.$index + 1}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="任务标题">
              <template slot-scope="scope">
                <span @click="previewTask(scope.row)" class="title-link">{{scope.row.title}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="类型" prop="type"></el-table-column>
            <el-table-column align="center" label="任务操作">
              <template slot-scope="scope">
                <el-popover
                  @hide="hideMethod"
                  placement="left"
                  trigger="click"
                  width="238"
                >
                  <ul class="stuList clearfix">
                    <li>
                      <span class="stu-tit">全体</span>
                      <el-switch
                        @change="switchCheckAll(scope.row, switchAll, scope.$index)"
                        class="stu-switch"
                        v-model="switchAll"
                      ></el-switch>
                    </li>
                    <li :key="index" v-for="(value, index) of scope.row.taskUserList">
                      <span class="stu-tit">{{value.name}}</span>
                      <el-switch
                        :active-value="1"
                        :inactive-value="2"
                        @change="switchCheckItem(value, scope.$index, index)"
                        class="stu-switch"
                        v-model="value.status === '已启用' ? 1 : 2"
                      ></el-switch>
                    </li>
                  </ul>
                  <el-button
                    slot="reference"
                    type="text"
                  >开放学员</el-button>
                </el-popover>
                <el-button
                  style="margin-left: 10px;"
                  v-if="scope.row.type === '测试'"
                  @click.stop="openmarkingDialog(scope.row.id)"
                  slot="reference"
                  type="text"
                >审阅试卷</el-button>
                <el-button
                  style="margin-left: 10px;"
                  v-if="scope.row.type === 'python'"
                  @click.stop="submitListManage(scope.row.id)"
                  slot="reference"
                  type="text"
                >查看提交</el-button>

                <el-dropdown  v-if="scope.row.type === '编程'" style="margin-left: 15px;">
            <span class="el-dropdown-link" type="primary">
              更多<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="queryItem(scope.$index)">
                      查看题库
                    </el-dropdown-item>
                    <el-dropdown-item  @click.native="setTime(scope.$index)">
                      设置查看时间
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
            <el-table-column align="center" label="互动课堂操作">
              <template slot-scope="scope">
                <el-popover
                  @hide="hideMethod"
                  placement="left"
                  trigger="click"
                  width="238"
                >
                  <ul class="stuList clearfix">
                    <li>
                      <span class="stu-tit">全部学员</span>
                      <el-button
                        @click.stop = "sendAllUser(scope.row,scope.$index)"
                        class="stu-switch"
                        slot="reference"
                        type="text"
                      >统一下发</el-button>
                    </li>
                    <li :key="index" v-for="(value, index) of scope.row.taskUserList">
                      <span class="stu-tit">{{value.name}}</span>
                      <el-button
                        @click.stop = "sendSingleUser(scope.row,value.loginName,value.name,value,scope.$index,index)"
                        class="stu-switch"
                        slot="reference"
                        type="text"
                      >下发任务</el-button>
                    </li>
                  </ul>
                  <el-button
                    slot="reference"
                    type="text"
                  >下发任务</el-button>
                </el-popover>

                <el-button
                  v-if="scope.row.type === '编程' || scope.row.type === 'python' "
                  @click.stop="openCodePage(scope.row.type,scope.row.id)"
                  slot="reference"
                  type="text"
                >查看代码</el-button>

              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="8">
          <el-table
            ref="userTable"
            style="width: 100%  !important;"
            :data="taskUserList"
            border
            fit
            highlight-current-row
            class="tb-list"
          >
            <el-table-column align="center" label="姓名">
              <template slot-scope="scope">
                <span>{{scope.row.name}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="学号">
              <template slot-scope="scope">
                <span>{{scope.row.loginName}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="状态">
              <template slot-scope="scope">
                <span v-if="scope.row.state === 1">在线</span>
                <span v-else-if="scope.row.state === 0">离线</span>
                <span v-else>无</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="正在做的任务" width="180">
              <template slot-scope="scope">
                <span v-if="scope.row.state === 1 && scope.row.pageState === 1">{{scope.row.pageTitle}}</span>
                <span v-if="scope.row.pageState === -1 || scope.row.pageState !== 1 ">无</span>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <!-- 审批试卷 -->
      <marking-exampaper
        :class_id="class_id"
        :lessonId="lessonId"
        :show="markingDialog"
        :taskID="taskID"
        @close="markingDialog=false"
      ></marking-exampaper>

      <!-- 课件补课视频 dialog -->
      <missed-lesson
        :applyCourseData="missLessonCourseData"
        :show="missLessonCourseFlag"
        @close="missLessonCourseFlag=false"
      ></missed-lesson>

      <set-history-time
        :taskId="taskID"
        :lessonId="lesson_id"
        :show="sethistoryDialog"
        @close="sethistoryDialog = false"
      ></set-history-time>

      <el-drawer
        :visible.sync="drawer"
        :with-header="false"
        direction="rtl"
        size="50%"
        :before-close="handleClose">
        <el-row style="overflow-y: scroll">
          <el-tabs v-model="activeName" @tab-click="handleClick" >
            <el-tab-pane v-for="(item) in dataOnlineList" :label="item.name" :name="item.loginName"></el-tab-pane>
          </el-tabs>
          <div v-if="codeTypeText === '编程'" class="ace-editor" ref="ace"></div>
          <el-row class="main"  v-if="codeTypeText === 'python'">
            <iframe
              :src="pathUrl"
              frameborder="1"
              allowfullscreen="true"
              width="100%"
              ref="leftFrame"
              height="800"
              seamless
              sandbox="allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups"
            >
            </iframe>
          </el-row>

        </el-row>
      </el-drawer>
    </el-row>
  </el-row>
</template>

<script>
  import { listCourseUnit, getInfo, switchCheckAll, switchCheckItem, queryQuestion,getAgoraToken } from '@/api/senate/class'
  import MissedLesson from '@/components/senate/missed-lesson'
  import setHistoryTime from '@/components/senate/set-historytime'
  import MarkingExampaper from '@/components/senate/marking-exampaper'

  import AgoraRTM from 'agora-rtm-sdk'
  import ace from 'ace-builds'
  import 'ace-builds/src-noconflict/snippets/javascript'
  import 'ace-builds/src-noconflict/snippets/html'
  import 'ace-builds/src-noconflict/snippets/java'
  import 'ace-builds/src-noconflict/snippets/c_cpp'
  import 'ace-builds/src-noconflict/snippets/pascal'
  import 'ace-builds/src-noconflict/snippets/ruby'
  import 'ace-builds/src-noconflict/snippets/batchfile'
  import 'ace-builds/src-noconflict/snippets/python'
  import 'ace-builds/src-noconflict/snippets/php'
  import 'ace-builds/src-noconflict/snippets/perl'
  import 'ace-builds/src-noconflict/snippets/objectivec'
  import 'ace-builds/src-noconflict/snippets/scheme'
  import 'ace-builds/src-noconflict/snippets/clojure'
  import 'ace-builds/src-noconflict/snippets/lua'
  import 'ace-builds/src-noconflict/snippets/golang'
  import 'ace-builds/src-noconflict/snippets/gobstones'
  import 'ace-builds/src-noconflict/snippets/django'
  import 'ace-builds/webpack-resolver'
  import 'ace-builds/src-noconflict/ext-language_tools'
  import 'ace-builds/src-noconflict/theme-monokai'
  import 'ace-builds/src-noconflict/theme-clouds_midnight'
  import 'ace-builds/src-noconflict/mode-javascript'


  export default {
    components: { MissedLesson, MarkingExampaper,setHistoryTime },
    data () {
      return {
        class_id: '', // 班级id
        lesson_id: '', // 排课id
        lessonId: '', // 排课id
        taskID: '', // 任务id
        taskList: [], // 课节的任务列表
        courseUnitList: [], // 课节列表
        courseTitle: '', // 课节名称
        switchAll: false,
        questionArray: [], // 题库列表
        lessonList: [],
        markingDialog: false,
        missLessonCourseData: {},
        missLessonCourseFlag: false,
        currentLesson: 0,
        sethistoryDialog:false,
        drawer:false,

        channel:null,
        dataOnlineList:[],

        codeMessage:"",
        aceEditor: null,
        themePath: 'ace/theme/monokai',
        wrap: true,
        activeName: '',
        taskUserList:[],
        enterCourse:"",
        selectCurrent:false,
        codeTypeText:'',
        codeTypeTaskId:'',
        pathUrl: '',
        countTest:1,
        lecturerName:'',
        assustantNames:'',
        lessonObject:{},
      }
    },
    mounted () {
      if(this.$route.query.lessonObject !== null && this.$route.query.lessonObject != undefined) {
        this.lessonObject = JSON.parse(this.$route.query.lessonObject)
      }

      this.pathUrl = process.env.VUE_APP_PYTHON_IDE+ "?pagetype=teacher&pagesource=submit"
      this.class_id = this.$route.query.classId
      this.lesson_id = this.$route.query.lessonId
      this.listCourseUnit()
      this.getTmsToken()
      if(this.lessonObject !== null && this.lessonObject !== undefined) {
        if(this.lessonObject.lessonTeacherList === undefined || this.lessonObject.lessonTeacherList === null) {
          this.lecturerName = this.lessonObject.zjName
          this.assustantNames = this.lessonObject.assistant
        }else {
          this.lessonObject.lessonTeacherList.forEach((item)=> {
            console.log(item.type)
            if(item.type === '主教') {
              this.lecturerName = item.teacher.name
            }else if(item.type === '助教') {
              this.assustantNames = this.assustantNames +','+ item.teacher.name
            }
          })
        }

      }
    },
    methods: {
      initRtcAceEditor() {
        this.aceEditor = ace.edit(this.$refs.ace, {
          fontSize: 20,
          value: this.codeMessage ? this.codeMessage : '',
          theme: this.themePath,
          wrap: this.wrap,
          tabSize: 4
        })
        ace.require('ace/ext/language_tools')
        // 激活自动提示
        this.aceEditor.setOptions({
          enableSnippets: true,
          enableLiveAutocompletion: true,
          enableBasicAutocompletion: true
        })
      },
      handleClose() {
        if(this.codeTypeText === '编程') {
          this.aceEditor.setValue('')
        }else if(this.codeTypeText === 'python') {
          this.$refs.leftFrame.contentWindow.postMessage({
            code:'please  wait .....',
            getCode:'setCode'
          }, '*')
        }

        let object = {
          type: 'closeTimer'
        }
        let textObjectString = JSON.stringify(object)
        this.channel.sendMessage({ text: textObjectString }).then(() => {
          /* 频道消息发送成功的处理逻辑 */
        }).catch(error => {
          console.log('sendMesssage=======>fail')
          /* 频道消息发送失败的处理逻辑 */
        });
        this.drawer = false
      },
      handleClick(tab) {
        console.log(this.activeName)
        let object = {
          type: 'closeTimer'
        }

        let textObjectString = JSON.stringify(object)
        this.channel.sendMessage({ text: textObjectString }).then(() => {
          let that = this
          if(that.codeTypeText === '编程') {
            this.aceEditor.setValue('')
          }else if(that.codeTypeText === 'python') {
            setTimeout(()=> {
              that.$refs.leftFrame.contentWindow.postMessage({
                code:'',
                getCode:'setCode'
              }, '*')
            },1000)
          }
          this.queryRemoteCode(this.activeName)
        }).catch(error => {
          console.log('sendMesssage=======>fail')
          /* 频道消息发送失败的处理逻辑 */
        });
      },
      async sendAllUser(row,parentIndex) {
        if(this.dataOnlineList.length === 0) {
          window.$msg('当前没有学员在线',2)
          return false
        }

        row.pageRtmType = 'openTaskAll'
        const res = await  this.openAllTask(row)
        if (res.state === 'success') {
          row.taskUserList.forEach((item)=> {
            item.status = '已启用'
          })
          for (let i = 0; i < this.taskList[parentIndex].taskUserList.length; i++
          ) {
            this.taskList[parentIndex].taskUserList[i].status = '已启用'
          }
        }

        let textObjectString = JSON.stringify(row)
        this.channel.sendMessage({ text: textObjectString }).then(() => {
          /* 频道消息发送成功的处理逻辑 */
          window.$msg('下发任务成功')
        }).catch(error => {
          console.log('sendMesssage=======>fail')
          /* 频道消息发送失败的处理逻辑 */
        });
      },
      async sendSingleUser(row,loginName,name,item,parentIndex, childindex){
        let onlineFlag = false
        this.dataOnlineList.forEach((item)=> {
          if(item.loginName === loginName) {
            onlineFlag = true
          }
        })
        if(!onlineFlag) {
          window.$msg('此学员处于离线状态',2)
          return false
        }

        row.pageRtmType = 'openTaskSingle'
        row.loginName = loginName

        row.taskUserList.forEach((item)=> {
          if(item.loginName === loginName) {
            row.taskStatus = item.status
            row.minHistoryTime = item.minHistoryTime
          }
        })

        if(row.taskStatus !== '已启用') {
          const res = await this.openSingleTask(item,parentIndex,childindex)
          console.log(JSON.stringify(res))
          if (res.state === 'success') {
            row.taskStatus = '已启用'
            this.taskList[parentIndex].taskUserList[childindex].status = '已启用'
          }
        }

        let textObjectString = JSON.stringify(row)
        this.channel.sendMessage({ text: textObjectString }).then(() => {
          /* 频道消息发送成功的处理逻辑 */
          window.$msg('已经向'+name+'下发任务成功')
        }).catch(error => {
          console.log('sendMesssage=======>fail')
          /* 频道消息发送失败的处理逻辑 */
        });
      },
      // 声网的方法开始
      //初始化rtm
      // token,appid,index
      initAgora(token,appid) {
        let that = this
        const client = AgoraRTM.createInstance(appid);
        client.on('ConnectionStateChanged', (newState, reason) => {
          console.log('on connection state changed to ' + newState + ' reason: ' + reason);
        });
        client.login({ token: token, uid:localStorage.getItem('userName') }).then(() => {
          console.log('AgoraRTM client login success');
          this.channel = client.createChannel(that.lesson_id); // 此处传入频道 ID
          this.channel.join().then(() => {
            that.enterCourse = '上课中'
            console.log('加入成功')

            this.channel.getMembers().then((members)=> {
              that.taskUserList.forEach((value)=> {
                value.state = 0
              })
              that.dataOnlineList = []
              /*获取此频道内的所有成员的uid,获取频道内的成员，然后跟任务中的成员对比，不在频道内的 成员视为不在线*/
              members.forEach((item)=> {
                that.taskUserList.forEach((value)=> {
                  if(value.loginName === item) {
                    value.state = 1
                    that.dataOnlineList.push(value)
                  }
                })
              })
              that.dataOnlineList=that.queryUnique(that.dataOnlineList, "userId")
              if(!that.selectCurrent) {
                that.selectCurrent = true
                that.$refs.userTable.setCurrentRow(that.taskUserList[0]);
              }else {
                that.selectCurrent = false
                that.$refs.userTable.setCurrentRow();
              }

            })
            let textObject =  {
              type:'queryStudentState',
            }
            let textObjectString = JSON.stringify(textObject)
            that.channel.sendMessage({ text: textObjectString }).then(() => {
              /* 频道消息发送成功的处理逻辑 */
              console.log('sendMesssage=======>success')
            }).catch(error => {
              console.log('sendMesssage=======>fail')
              /* 频道消息发送失败的处理逻辑 */
            });
            that.$forceUpdate()
          }).catch(error => {
            /* 加入频道失败的处理逻辑 */
          });

          this.channel.on('ChannelMessage', ({ text }, senderId) => { // text 为收到的频道消息文本，senderId 为发送方的 User ID
            /* 收到频道消息的处理逻辑 */
            console.log(text)
            let dataObject = JSON.parse(text)

            if(dataObject.type === 'sendCurrentCode' && dataObject.taskId === that.codeTypeTaskId && senderId.toUpperCase() === that.activeName.toUpperCase()) {
              if(that.codeTypeText === '编程' && dataObject.language === 'C++') {
                this.codeMessage = dataObject.code
                this.aceEditor.setValue(dataObject.code)
              }else if(that.codeTypeText === 'python' && dataObject.language === 'python') {
                setTimeout(()=> {
                  that.$refs.leftFrame.contentWindow.postMessage({
                    code:dataObject.code,
                    getCode:'setCode'
                  }, '*')
                },1000)
              }

            }else if (dataObject.type === 'pageVisibility') {
              that.taskUserList.forEach((item) => {
                if(item.loginName === senderId) {
                  item.pageState = dataObject.pagestate
                  item.pageTitle = dataObject.pageTitle
                }
              })
              if(!that.selectCurrent) {
                that.selectCurrent = true
                that.$refs.userTable.setCurrentRow(that.taskUserList[0]);
              }else {
                that.selectCurrent = false
                that.$refs.userTable.setCurrentRow();
              }
            }

          });

          this.channel.on('MemberJoined', (memberId) => { // text 为收到的频道消息文本，senderId 为发送方的 User ID
            /* 此频道内有新的成员加入回调 */
            console.log(memberId+'====加入了频道')
            that.taskUserList.forEach((value)=> {
              value.state = 0
            })
            that.dataOnlineList = []
            this.channel.getMembers().then((members)=> {
              members.forEach((item)=> {
                that.taskUserList.forEach((value)=> {
                  if(value.loginName === item) {
                    value.state = 1
                  }
                })
              })
              that.taskUserList.forEach((item)=> {
                if(item.state === 1) {
                  that.dataOnlineList.push(item)
                }
              })
              that.dataOnlineList=that.queryUnique(that.dataOnlineList, "userId")
              console.log(JSON.stringify(that.dataOnlineList))
              if(!that.selectCurrent) {
                that.selectCurrent = true
                that.$refs.userTable.setCurrentRow(that.taskUserList[0]);
              }else {
                that.selectCurrent = false
                that.$refs.userTable.setCurrentRow();
              }
            })
          });
          this.channel.on('MemberLeft', (memberId) => { // text 为收到的频道消息文本，senderId 为发送方的 User ID
            /* 此频道内有成员退出回调 */
            console.log(memberId+'====离开了频道')
            that.taskUserList.forEach((value)=> {
              value.state = 0
            })
            that.dataOnlineList = []
            this.channel.getMembers().then((members)=> {
              /*获取此频道内的所有成员的uid*/
              members.forEach((item)=> {
                that.taskUserList.forEach((value)=> {
                  if(value.loginName === item) {
                    value.state = 1
                  }
                })
              })
              that.taskUserList.forEach((item)=> {
                if(item.state === 1) {
                  that.dataOnlineList.push(item)
                }
              })
              that.dataOnlineList=that.queryUnique(that.dataOnlineList, "userId")
              console.log(JSON.stringify(that.dataOnlineList))
              if(!that.selectCurrent) {
                that.selectCurrent = true
                that.$refs.userTable.setCurrentRow(that.taskUserList[0]);
              }else {
                that.selectCurrent = false
                that.$refs.userTable.setCurrentRow();
              }
            })
          });
        }).catch(err => {
          console.log('AgoraRTM client login failure', err);
        });

      },
      queryUnique(arr, val) {
        const res = new Map()
        return arr.filter((item) => !res.has(item[val]) && res.set(item[val], 1))
      },
      openCodePage(codeType,id){
        this.codeTypeTaskId = id
        this.codeTypeText = codeType
        if(this.dataOnlineList.length === 0) {
          window.$msg('当前没有学员在线',2)
          return false
        }

        this.drawer = true
        this.activeName = this.dataOnlineList[0].loginName
        this.$nextTick(() => {
          if(codeType === '编程') {
            this.initRtcAceEditor()
          }else if(codeType === 'python') {
            setTimeout(()=> {
              this.$refs.leftFrame.contentWindow.postMessage({
                code:'please  wait .....',
                getCode:'setCode'
              }, '*')
            },1000)
          }
          setTimeout(()=> {
            this.queryRemoteCode(this.activeName)
          },2000)
        })
      },
      queryRemoteCode(loginName) {

        let textObject =  {
          type:'queryCode',
          loginName:loginName,
          taskType:this.codeTypeText
        }
        let textObjectString = JSON.stringify(textObject)
        this.channel.sendMessage({ text: textObjectString }).then(() => {
          /* 频道消息发送成功的处理逻辑 */
          console.log('sendMesssage=======>success')
        }).catch(error => {
          console.log('sendMesssage=======>fail')
          /* 频道消息发送失败的处理逻辑 */
        });
      },
      setTask(index) {
        this.channel.sendMessage({ text: 'test channel message opentask' }).then(() => {
          /* 频道消息发送成功的处理逻辑 */

          console.log('sendMesssage=======>success')
        }).catch(error => {
          console.log('sendMesssage=======>fail')
          /* 频道消息发送失败的处理逻辑 */
        });
      },
      //声网的方法结束

      setTime(index) {
        this.taskID = this.taskList[index].id
        this.sethistoryDialog = true
      },
      async listCourseUnit () {
        const res = await listCourseUnit({
          // classId: this.class_id
          lessonId: this.lesson_id
        })
        this.courseUnitList = res.body
        if (!this.$route.query.courseId) {
          this.getInfo(res.body[this.currentLesson].id)
        } else {
          res.body.forEach((ele, idx) => {
            if (ele.id === this.$route.query.courseId) {
              this.currentLesson = ele.sort - 1
              this.getInfo(ele.id)
            }
          })
        }
      },

      async getInfo (id) {
        let that = this
        const res = await getInfo({
          id,
          classId: this.class_id,
          lessonId:this.$route.query.lessonId,
          onlyEnable: true
        })

        this.lessonList = res.body.lessonList
        this.courseTitle = res.body.title
        this.taskList = res.body.taskList
        this.taskUserList = this.taskList[0].taskUserList
        this.taskUserList.forEach((item) => {
          item.pageState = -1
        })
        this.getTmsToken()
      },

      async switchCheckAll (row, status, parentIndex) {
        if (row.taskUserList.length === 0) {
          return false
        }
        var userIdArray = []
        for (let i = 0; i < row.taskUserList.length; i++) {
          userIdArray.push(row.taskUserList[i].userId)
        }
        if (!status) {
          status = '已禁用'
        } else {
          status = '已启用'
        }
        const res = await switchCheckAll({
          taskId: row.taskUserList[0].taskId,
          userIds: userIdArray,
          status: status
        })
        if (res.state === 'success') {
          if (status === '已禁用') {
            for (let i = 0; i < this.taskList[parentIndex].taskUserList.length; i++) {
              this.taskList[parentIndex].taskUserList[i].status = '已禁用'
            }
          } else {
            for (let i = 0; i < this.taskList[parentIndex].taskUserList.length; i++
            ) {
              this.taskList[parentIndex].taskUserList[i].status = '已启用'
            }
          }
        }
      },

      async switchCheckItem (item, parentIndex, childindex) {
        var status = 0
        var userIdArray = []
        userIdArray.push(item.userId)
        if (item.status === '已启用') {
          status = '已禁用'
        } else {
          status = '已启用'
        }
        const res = await switchCheckItem({
          taskId: item.taskId,
          userIds: userIdArray,
          status: status
        })
        if (res.state === 'success') {
          if (item.status === '已启用') {
            this.taskList[parentIndex].taskUserList[childindex].status = '已禁用'
          } else {
            this.taskList[parentIndex].taskUserList[childindex].status = '已启用'
          }
        }
      },

      async openSingleTask (item, parentIndex, childindex) {
        var status = 0
        var userIdArray = []
        userIdArray.push(item.userId)

        const res = await switchCheckItem({
          taskId: item.taskId,
          userIds: userIdArray,
          status: '已启用'
        })

        return res
      },

      async openAllTask (row) {
        if (row.taskUserList.length === 0) {
          return false
        }
        var userIdArray = []
        for (let i = 0; i < row.taskUserList.length; i++) {
          userIdArray.push(row.taskUserList[i].userId)
        }

        const res = await switchCheckAll({
          taskId: row.taskUserList[0].taskId,
          userIds: userIdArray,
          status: '已启用'
        })

        return res
      },

      async queryQuestion (parentIndex) {
        const res = await queryQuestion({
          classId: this.class_id,
          taskId: this.taskList[parentIndex].id
        })
        if (res.state === 'success') {
          this.questionArray = res.body
        }
      },

      async getCourseUnitList (courseId) {
        const res = await getInfo({
          id: courseId,
          classId: this.class_id,
          onlyEnable: true
        })

        this.taskList = []
        this.lessonList = []
        this.taskList = res.body.taskList

        this.taskUserList = this.taskList[0].taskUserList
        this.lessonList = res.body.lessonList
      },

      submitListManage (taskId) {
        this.$router.push({
          path: '/senate/submit_list',
          query: {
            taskId: taskId,
            lessonId: this.lesson_id,
            type: 'pythontask'
          }
        })
      },

      openmarkingDialog (taskId) {
        this.lessonId = this.lesson_id
        this.taskID = taskId
        this.markingDialog = true
      },
      async getTmsToken() {
        console.log('11111111777777777')
        const response = await  getAgoraToken({
          loginName:localStorage.getItem('userName')
        })
        if(response.body.appid === null || response.body.rtmToken === null) {
          window.$msg("token值为空，不能登录直播间，请联系管理员",2)
          return false
        }
        this.initAgora(response.body.rtmToken,response.body.appId)
      },
      queryItem (parentIndex) {
        var row = {}
        row.lesson_id = this.lesson_id
        row.class_id = this.class_id
        row.contest_id = this.taskList[parentIndex].sourceId
        row.className = ''
        // 跳转到题库
        this.$router.push({
          path: '/senate/class-quesbank-detail',
          query: {
            activeName: 'first',
            classData: JSON.stringify(row)
          }
        })
      },

      // 预览任务
      previewTask (activity) {
        let routeData = this.$router.resolve({
          path: '/research/preview-task',
          query: {
            classID: this.$route.query.classID,
            taskID: activity.id
          }
        })
        window.open(routeData.href, '_blank')
      },

      hideMethod () {
        this.switchAll = false
      },

      // 上一节
      prevLesson () {
        this.currentLesson--
        if (this.currentLesson < 0) {
          // window.$msg('已是第一节课', 2)
          this.currentLesson = 0
          return false
        } else {
          // console.log('currentLesson', this.currentLesson)
          this.getInfo(this.courseUnitList[this.currentLesson].id)
        }
      },

      // 下一节
      nextLesson () {
        this.currentLesson++
        if (this.currentLesson === this.courseUnitList.length || this.currentLesson > this.courseUnitList.length) {
          // window.$msg('已是最后一节课', 2)
          this.currentLesson = this.courseUnitList.length - 1
          return false
        } else {
          // console.log('currentLesson', this.currentLesson)
          this.getInfo(this.courseUnitList[this.currentLesson].id)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../style/table.scss";
  .ace-editor {
    width: 100%;
    height: 800px;
    min-width: 30%;
    min-height: 150px;
    overflow-y: hidden;
  }
  .el-icon-arrow-down {
    font-size: 14px;
  }
  .stuList {
    display: flex;
    flex-direction: column;
    height: 300px;
    margin-top: 10px;
    overflow-y: auto;
    li {
      margin-top: 8px;
      margin-bottom: 8px;
      line-height: 32px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .stu-tit {
      display: inline-block;
      padding-left: 10px;
      justify-content: flex-start;
      cursor: pointer;
    }
    .stu-switch {
      float: right;
    }
    .main {
      background: #fff;
      overflow: auto;
      height: 800px;
      padding: 0;
      .content {
        min-width: 992px;
        max-width: 1400px;
        margin: 0 auto 0;
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: justify;
        color: #616161;
      }
    }
  }
</style>
